import { toast } from "react-toastify";

export function formatCash(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const _updatePhoneNumber = (_searchValue) => {
  _searchValue = _searchValue.replace("+", "");

  if (
    _searchValue.startsWith("07") ||
    _searchValue.startsWith("02") ||
    _searchValue.startsWith("01")
  ) {
    return _searchValue.replace("0", "254");
  }
  return _searchValue;
};

export const apiErrorHandler = (_err) => {
  console.log(_err);
  toast.error(`Error updating details, please try again.`);
};

export function insertSpinner() {
  let spinnerContainer = document.createElement("div");
  spinnerContainer.classList.add("spinner-border");
  spinnerContainer.setAttribute("role", "status");
  let spinner = document.createElement("span");
  spinner.className = "sr-only";
  spinner.textContent = "Loading...";
  spinnerContainer.appendChild(spinner);

  return spinnerContainer;
}

export function fetchErrorUI(
  _err,
  _message = "An error Occured while fetching some data please reload page!"
) {
  toast.error(_message, { position: toast.POSITION.TOP_CENTER });
}

export function postErrorUI(
  _message = "An error Occured while posting your updates. Please try again!"
) {
  toast.error(_message, { position: toast.POSITION.TOP_CENTER });
}

export function capitalizeFirstLetter(str) {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

// function getOwnerMatatus() {
//   const profile = JSON.parse(window.sessionStorage.getItem("profile"));
//   return profile.role.toLowerCase() == "owner" ? profile.matatuList : "";
// }

export const setStatusClass = (_status) => {
  switch (_status) {
    case true:
      return "badge-success";
    case false:
      return "badge-secondary";
    // temporarily replaced status with operatorIsVerified key
    case "active":
      return "badge-success";
    case "inactive":
      return "badge-secondary";
    case "suspended":
      return "badge-danger";
    case "registered":
      return "badge-secondary";
    default:
      // status pending
      return "badge-warning";
  }
};

export const setStatusColor = (status) => {
  switch (status) {
    case "online":
      return "badge-success-lighten";
    case "offline":
      return "badge-danger-lighten";
    default:
      return "badge-secondary-lighten";
  }
};

export const verifyMatatu = (_matatu, _action) => {
  let _errorMessage = "";

  if (_action === "save") {
    if (!_matatu.licensePlate) _errorMessage += "License Plate ,";
    if (!_matatu.numberOfSeats) _errorMessage += "Number Of Seats, ";
  }

  if (!_matatu.routeNumber) _errorMessage += "Route Number, ";
  if (!Object.keys(_matatu.operators).length) _errorMessage += "Crew, ";
  if (!_matatu.saccoName) _errorMessage += "Sacco Name, ";

  if (_errorMessage) {
    toast.error("Missing required fields: " + _errorMessage);
    return false;
  }
  return true;
};

export const verifyCrewMember = (_crew, _action) => {
  let _errorMessage = "";

  if (_action === "save") {
    if (!_crew.firstName) _errorMessage += "First Name, ";
    if (!_crew.lastName) _errorMessage += "Last Name, ";
    if (!_crew.phoneNumber) _errorMessage += "Phone Number, ";
    if (!_crew.idNumber) _errorMessage += "ID Number. ";
  }

  if (!_crew.matatu) _errorMessage += "Matatu ";
  if (!_crew.role) _errorMessage += "Role ";
  // if (!_crew.status) _errorMessage += "Status ";

  if (_errorMessage) {
    toast.error("Missing required fields: " + _errorMessage);
    return false;
  }
  return true;
};

export const verifyParcelDetails = (_parcel, _action) => {
  let _errorMessage = "";
  if (_action === "save") {
    //check sender details validity
    if (!_parcel.sender.firstName) _errorMessage += "Sender First Name, ";
    if (!_parcel.sender.lastName) _errorMessage += "Sender Last Name, ";
    if (!_parcel.sender.phoneNumber) _errorMessage += "Sender Phone Number, ";
    if (!_parcel.sender.idNumber) _errorMessage += "Sender ID Number. ";

    //check receiver details validity
    if (!_parcel.recipient.firstName) _errorMessage += "Receiver First Name, ";
    if (!_parcel.recipient.lastName) _errorMessage += "Receiver Last Name, ";
    if (!_parcel.recipient.phoneNumber)
      _errorMessage += "Receiver Phone Number, ";
    if (!_parcel.recipient.idNumber) _errorMessage += "Receiver ID Number. ";
  }

  if (!_parcel.matatu) _errorMessage += "Matatu ";
  if (!_parcel.origin) _errorMessage += "Origin ";
  if (!_parcel.destination) _errorMessage += "Destination ";
  if (!_parcel.amountPaid) _errorMessage += "Amount ";

  if (_errorMessage) {
    toast.error("Missing required fields: " + _errorMessage);
    return false;
  }
  return true;
};

export const formatSaccoMember = (_user) => {
  return {
    email: _user.email,
    firstName: _user.firstName,
    id: _user.id,
    idNumber: _user.idNumber,
    isVerified: _user.verified,
    lastName: _user.lastName,
    matatuList: _user.matatuList,
    photo: _user.photo,
    role: _user?.role,
    sacco: _user.sacco,
  };
};
export function formatDate(value) {
  // formating for date filters
  let _day = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
  let _month =
    value.getMonth() + 1 < 10
      ? "0" + (value.getMonth() + 1)
      : value.getMonth() + 1;

  return parseInt(value.getFullYear().toString()) + "-" + _month + "-" + _day;
}

export const userAccessLevel = (user, location = "") => {
  const _allowedToWithdraw = ["Admin", "MainBrandAmbassador"];
  const _beyondBasicAccess = [..._allowedToWithdraw, "BrandAmbassador"];
  const _allAccessPages = ["/profile", "/operators"];

  if (user?.role === "Admin") {
    return { level: "admin", allowedPage: true };
  } else if (_allowedToWithdraw.includes(user?.role)) {
    return { level: "withdrawal", allowedPage: true };
  } else if (_beyondBasicAccess.includes(user?.role)) {
    return { level: "registration", allowedPage: location !== "/withdraw" };
  } else if (!_beyondBasicAccess.includes(user?.role)) {
    return { level: "basic", allowedPage: _allAccessPages.includes(location) };
  } else {
    return "";
  }
};

export const getAuthState = () =>
  window.localStorage.getItem("authState") === "true" ? true : false;
