/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-undef
import config from "../config.json";

const apiUrl = config.API_URL;

export function appendHeaders() {
  const token = window.sessionStorage.getItem("betkn");
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
}

export function getFaresQuery(faresQuery) {
  return fetch(apiUrl + "/v1/fare/query", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(faresQuery),
  }).then(function (response) {
    return response.json().then((_res) => {
      return _res;
    });
  });
}

export function getSaccoRoutes(saccoName) {
  return fetch(apiUrl + "/v1/sacco", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify({
      saccoName: saccoName,
    }),
  }).then(function (response) {
    return response.json();
  });
}

export function fetchTrips(saccoName) {
  // TODO: revert
  return fetch(apiUrl + "/v1/trips/query/" + saccoName, {
    // return fetch("https://run.mocky.io/v3/3827d550-7534-4865-99a8-c8133bd845a5", {
    method: "GET",
    headers: appendHeaders(),
  })
    .then(async function (response) {
      return await response.json();
    })
    .then((_data) => {
      return _data;
    });
}

export function getDetails() {}

export function editFares(editData) {
  return fetch(apiUrl + "/v1/fare", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(editData),
  }).then(function (response) {
    return response;
  });
}

export function homeSummary(summaryQueryPhone) {
  return fetch(apiUrl + "/v1/summary", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify({
      phone: summaryQueryPhone,
    }),
  }).then(function (response) {
    return response.json();
  });
}

export function fetchOwnerMatatus(owner) {
  return fetch(apiUrl + "/sacco/member/matatu/query", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(owner),
  }).then(function (response) {
    return response.json();
  });
}

export function fetchRecentTransactions(user) {
  // TODO: revert
  // return fetch("https://run.mocky.io/v3/dd696c59-54b6-4260-be45-ca27f28f64f3", {
  return fetch(apiUrl + "/dashboard/recent-transactions", {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (response) {
    return response.json();
  });
}

export function fetchNotifications(
  saccoName,
  page = 0,
  size = 20,
  recipient = "",
  startDate = "",
  endDate = ""
) {
  let _query = "";
  if (recipient) _query += `&recipient=${recipient}`;
  if (startDate) _query += `&startDate=${startDate}`;
  if (endDate) _query += `&endDate=${endDate}`;

  return fetch(
    apiUrl +
      `/notification?page=${page}&size=${size}&saccoName=${saccoName}${_query}`,
    {
      method: "GET",
      headers: appendHeaders(),
    }
  )
    .then(async function (response) {
      return await response.json();
    })
    .then((_data) => {
      return _data;
    });
}

// crew management api calls
export function approveOperator(operator) {
  return fetch(apiUrl + "/crew/approve", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(operator),
  }).then(function (response) {
    return response;
  });
}

export function fetchCrew(saccoName) {
  return fetch(apiUrl + "/sacco/crew/" + saccoName, {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (response) {
    return response.json();
  });
}

export function registerCrewMember(crewMember) {
  return fetch(apiUrl + "/crew/register", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(crewMember),
  }).then(function (response) {
    return response;
  });
}

export function updateCrewDetails(crewMember) {
  return fetch(apiUrl + "/crew/details", {
    method: "PATCH",
    headers: appendHeaders(),
    body: JSON.stringify(crewMember),
  }).then(function (response) {
    return response.json();
  });
}

export function activateCrewMember(crewMember) {
  return fetch(apiUrl + "/crew/post", {
    method: "GET",
    headers: appendHeaders(),
    body: JSON.stringify(crewMember),
  }).then(function (response) {
    return response.json();
  });
}

// crew api calls end

// parcel service api calls
export function fetchParcels(
  saccoName,
  page = 0,
  size = 20,
  recipient = "",
  startDate = "",
  endDate = ""
) {
  let _query = "";
  if (recipient) _query += `&recipient=${recipient}`;
  if (startDate) _query += `&startDate=${startDate}`;
  if (endDate) _query += `&endDate=${endDate}`;

  return fetch(
    apiUrl +
      `/parcel?page=${page}&size=${size}&saccoName=${saccoName}${_query}`,
    {
      // return fetch(apiUrl + "/sacco/crew/" + saccoName, {
      //return fetch("https://run.mocky.io/v3/1565da3b-9c6e-49c0-aff2-2cf789e47230", {
      method: "GET",
      headers: appendHeaders(),
    }
  ).then(function (response) {
    return response.json();
  });
}

export async function createNewParcel(parcel) {
  console.log("create new parcel: ", parcel);
  // return { status: 200 };
  return fetch(apiUrl + "/parcel/register", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(parcel),
  }).then(function (response) {
    return response;
  });
}

export function updateParcelDetails(parcel) {
  console.log("update parcel details: ", parcel);
  // return fetch(apiUrl + "/crew/details", {
  //   method: "PATCH",
  //   headers: appendHeaders(),
  //   body: JSON.stringify(crewMember),
  // }).then(function (response) {
  //   return response.json();
  // });
}
//parcel api calls end

export function registerMatatu(matatu) {
  return fetch(apiUrl + "/crew/register/matatu", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(matatu),
  }).then(function (response) {
    return response;
  });
}

export function approveMatatu(matatu) {
  return fetch(apiUrl + "/v1/matatu/approve", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(matatu),
  }).then(function (response) {
    return response;
  });
}

export function fetchRevenue() {
  return fetch(apiUrl + "/dashboard/revenue", {
    method: "GET",
    headers: appendHeaders(),
  }).then(async function (response) {
    return await response.json();
  });
}

export function fetchDaySummary(date) {
  // TODO: revert
  return fetch(apiUrl + "/dashboard/summary-by-date", {
    // return fetch("https://run.mocky.io/v3/d325b41b-259c-40fb-89a0-14b6286b9041", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify({
      date,
    }),
  }).then(function (response) {
    return response.json();
  });
}

export function fetchMatatuQueue(saccoName) {
  // return fetch(
  //   "https://run.mocky.io/v3/48ef5d54-8e4c-46f2-ad75-1dea56ce30d0"
  return fetch(apiUrl + "/queue/" + saccoName, {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (_res) {
    return _res.json();
  });
}

// fetch list of matatus based on sacco
export function fetchMatatus(saccoName) {
  // TODO: revert
  return fetch(apiUrl + "/sacco/matatu/" + saccoName, {
    // return fetch("https://run.mocky.io/v3/9a06fdf7-c9e9-4790-9ccf-a1a6f366e3bb", {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (_res) {
    return _res.json();
  });
}

// register to Sacco
export function registerToSacco(_user) {
  return fetch(apiUrl + "/sacco/member", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(_user),
  }).then((_res) => _res.json());
}

// edit profile details
export function editProfile(editProfile) {
  return fetch(apiUrl + "/sacco/member", {
    method: "PATCH",
    headers: appendHeaders(),
    body: JSON.stringify(editProfile),
  }).then(function (response) {
    return response.json();
  });
}

// dashboard login
export function dashboardLogin() {
  const _credentials = { userName: "donApi", password: "Dond@a22" };
  return fetch(apiUrl + "/dashboard/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_credentials),
  }).then(async (_res) => {
    return await _res.json();
  });
}

// withdraw
export function initiateWithdraw(_withdrawal) {
  return fetch(apiUrl + "/crew/withdraw", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(_withdrawal),
  }).then(function (response) {
    return response.json();
  });
}

// withdraw savings
export function initiateSavingsWithdrawal(_withdrawal) {
  return fetch(apiUrl + "/payment/savings/withdraw", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(_withdrawal),
  }).then(function (response) {
    return response.json();
  });
}

// savings deposit
export function initiateSavingsDeposit(_deposit) {
  return fetch(apiUrl + "/savings/deposit", {
    method: "POST",
    headers: appendHeaders(),
    body: JSON.stringify(_deposit),
  }).then(function (response) {
    return response.json();
  });
}

// fetch wallet details
export function fetchWalletDetails(walletId) {
  return fetch(apiUrl + "/wallet/" + encodeURIComponent(walletId), {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (response) {
    return response.json();
  });
}

// fetchSavings transactions
export function fetchSavingsTransactions(page = 0, size = 20) {
  return fetch(apiUrl + `/savings/?page=${page}&size=${size}`, {
    method: "GET",
    headers: appendHeaders(),
  })
    .then(async function (response) {
      return await response.json();
    })
    .then((_data) => {
      return _data;
    });
}

// fetch matatu by licenseplate
export function fetchMatatuByPlate(walletId) {
  return fetch(
    apiUrl + "/sacco/matatu/profile/" + encodeURIComponent(walletId),
    {
      method: "GET",
      headers: appendHeaders(),
    }
  ).then(function (response) {
    return response.json();
  });
}

// fetch top routes
export function fetchTopRoutes() {
  return fetch(apiUrl + "/dashboard/top-routes", {
    // return fetch("https://run.mocky.io/v3/b20fb2dd-f422-42b9-ae53-c970b78b925f", {
    method: "GET",
    headers: appendHeaders(),
  }).then(function (response) {
    return response.json();
  });
}
