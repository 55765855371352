export const crewRoles = ["driver", "conductor", "driveronly"];

export const crewMatatuStatus = ["active", "suspended", "inactive"];

export const authorisedPages = [
  "/index.html",
  "/profile.html",
  "/",
  "register.html",
];

//   firebase config
export const firebaseConfig = {
  apiKey: "AIzaSyD0erYBKr8e0fB2Y626a5Q_biCFAa9eyOM",
  authDomain: "abiria-op.firebaseapp.com",
  databaseURL: "https://abiria-op.firebaseio.com",
  projectId: "abiria-op",
  storageBucket: "abiria-op.appspot.com",
  messagingSenderId: "179639161380",
  appId: "1:179639161380:web:f3209af5c654dfdd46beb1",
};

export const routeColors = {
  33: "#345496",
  58: "#773db4",
  237: "#0ABE78",
  111: "#426EFF",
  46: "#ef6c00",
  105: "#4e342e",
  "4W": "#455a64",
  119: "#1a237e",
  118: "#b71c1c",
  "17B": "#c43e00",
};
