import React from "react";
import loadingGIF from "../../assets/images/loading-spinner.gif";

const PageLoadingSpinner = () => {
  return (
    <div id="page-loader" className="text-center">
      <img
        src={loadingGIF}
        alt=""
        style={{ height: "3.5rem", width: "auto" }}
      />
    </div>
  );
};

export default PageLoadingSpinner;
